import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import styled, { css } from 'styled-components';
import intlShape from '../../../../../js/schemas/intl';
import tooltipSchema from '../../../../../js/schemas/tooltip';
import tooltipToObject from '../../../../../js/helpers/tooltipToObject';
import Tooltips from '../../../../utils/tooltips';
import Shield from '../../../../../js/images/shield.svg';
import SalarySuggestion from '../../../../utils/SalarySuggestion';

const Wrapper = styled.div`
  && {
    margin: ${(props) => props.margin || '0 0 1rem 0'};
    display: flex;
    position: relative;
    flex-direction: column;
  }
`;

const EngSuffix = styled.div`
  && {
    margin-left: 0.5rem;
    font-weight: bold;
    color: var(--teal);
  }
`;

const Suffix = styled.div`
  && {
    color: var(--black);
    font-weight: 700;
    padding-right: 0.5rem;
  }
`;

const LabelWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    padding: 0 0 0.5rem;
  }
`;

const Label = styled.label`
  && {
    position: relative;
    font-size: 1rem;
    z-index: 1;
  }
`;

const ShieldWrapper = styled.div`
  && {
    width: 1rem;
    height: 1rem;
    margin-top: -0.2em;
    fill: var(--dark-teal-60);

    ${(props) => props.valid && props.validated && css`
    fill:  var(--teal);
    `}
  }
`;

const Input = styled.input`
  && {
    background: var(--off-white);
    border: 0;
    -ms-flex: 1 1;
    flex: 1 1;
    height: 100%;
    outline: none;
    overflow: hidden;
    padding: 0 0.5rem;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    ${(props) => props.totalAmountField && css`
      color: var(--black);
      font-weight: 700!important;
    `}
  }
`;

const Field = styled.div`
  && {
    align-items: center;
    background: var(--off-white);
    border: 0;
    border-radius: 5px;
    -webkit-box-sizing: border-box;
    border: 2px solid var(--dark-teal-20);
    box-sizing: border-box;
    cursor: text;
    display: -ms-flexbox;
    display: flex;
    height: 3rem;
    justify-content: space-between;
    overflow: hidden;

    ${(props) => props.valid && props.validated && css`
      border-color:  var(--teal);
    `}

    ${(props) => (props.validated && props.valid === false) && css`
      border-color: var(--red);
    `}

    ${(props) => props.valid === null && css`
      border-color: var(--dark-teal-20);
    `}
  }
`;

Label.displayName = 'label';

const TextInput = ({
  intl: { formatMessage },
  id,
  name,
  compact,
  labelOutside,
  label,
  placeholder,
  suffix,
  removeSuffix,
  validated,
  valid,
  className,
  margin,
  tooltip,
  tooltipLink,
  whitelist,
  disableButton,
  submitSuccess,
  expandedForm,
  setShowErrors,
  passedRef,
  changeField,
  descriptionText,
  inputRef,
  contactForm,
  isNumber,
  englishSuffix,
  totalAmountField,
  ...rest
}) => {
  // eslint-disable-next-line
  const inputId = id || `text-input--${name || ''}--${label.replace(/\s/, '-')}`;

  const WebsiteLink = (chunks) => <a href={tooltipLink} target="_blank" rel="noreferrer">{chunks}</a>;

  const tooltipObject = tooltipToObject(tooltip);
  if (typeof tooltip === 'object') {
    tooltipObject.text = formatMessage(tooltip.text, {
      a: WebsiteLink,
    });
  }

  return (
    <Wrapper
      margin={margin}
    >
      {label && (contactForm || label.id) && (
      <LabelWrapper>
        <Label htmlFor={inputId}>
          {contactForm ? label : formatMessage(label)}
        </Label>
        { tooltipObject.text
          && (
            <Tooltips tooltipObject={tooltipObject} />
          )}
      </LabelWrapper>
      )}
      <Field
        validated={validated}
        valid={valid}
      >
        {englishSuffix && (
        <EngSuffix>
          <span translate="no">{formatMessage(suffix)}</span>
        </EngSuffix>
        )}
        {(placeholder || contactForm) && (placeholder?.id || contactForm) && (
          <Input
            ref={inputRef}
            id={inputId}
            name={name}
            placeholder={contactForm ? placeholder : formatMessage(placeholder)}
            data-hj-whitelist={whitelist || null}
            tabIndex="0"
            totalAmountField={totalAmountField}
            {...rest}
          />
        )}
        {(validated || suffix) && !removeSuffix && !englishSuffix && (
        <Suffix>
          {suffix
            ? <span translate="no">{formatMessage(suffix)}</span>
            : (
              <ShieldWrapper
                validated={validated}
                valid={valid}
              >
                <Shield />
              </ShieldWrapper>
            )}
        </Suffix>
        )}
      </Field>
      {String(name) === 'applicant.monthlySalary'
        && (
        <SalarySuggestion />
        )}
    </Wrapper>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  intl: intlShape.isRequired,
  type: PropTypes.oneOf(['text', 'tel', 'email', 'password', 'url', 'search']),
  name: PropTypes.string,
  compact: PropTypes.bool,
  labelOutside: PropTypes.bool,
  label: PropTypes.shape({
    id: PropTypes.string,
    defaultMessage: PropTypes.string,
    description: PropTypes.string,
  }),
  suffix: PropTypes.shape(),
  englishSuffix: PropTypes.bool,
  removeSuffix: PropTypes.bool,
  validated: PropTypes.bool,
  valid: PropTypes.bool,
  className: PropTypes.string,
  tooltip: tooltipSchema,
  tooltipLink: PropTypes.string,
  override: PropTypes.shape({
    input: PropTypes.shape({
      className: PropTypes.string,
      labelClassName: PropTypes.string,
    }),
  }),
  whitelist: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.func,
  ]),
  contactForm: PropTypes.bool,
  placeholder: PropTypes.shape(),
  margin: PropTypes.string,
  isNumber: PropTypes.bool,
  disableButton: PropTypes.bool,
  submitSuccess: PropTypes.bool,
  expandedForm: PropTypes.bool,
  setShowErrors: PropTypes.func,
  passedRef: PropTypes.shape(),
  changeField: PropTypes.func,
  descriptionText: PropTypes.string,
  totalAmountField: PropTypes.bool,
};

TextInput.defaultProps = {
  id: null,
  type: 'text',
  name: null,
  compact: false,
  labelOutside: false,
  label: {},
  suffix: '',
  englishSuffix: false,
  removeSuffix: false,
  validated: false,
  valid: null,
  className: '',
  tooltip: '',
  tooltipLink: '',
  override: {
    input: {},
  },
  whitelist: false,
  inputRef: null,
  contactForm: false,
  placeholder: {},
  margin: '',
  isNumber: false,
  disableButton: false,
  submitSuccess: false,
  expandedForm: false,
  setShowErrors: () => {},
  passedRef: {},
  changeField: () => {},
  descriptionText: '',
  totalAmountField: false,
};

export default injectIntl(TextInput);
