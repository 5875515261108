import textResource from '../../../../utils/text-resources/advisa/SE/messages';

const HOME_TYPES = [
  {
    label: textResource.homeTypeRentingApartment,
    value: 'rentingApartment',
  },
  {
    label: textResource.homeTypeHouseOrTownhouse,
    value: 'houseOrTownhouse',
  },
  {
    label: textResource.homeTypeCondominium,
    value: 'condominium',
  },
  {
    label: textResource.homeTypeRentingRoom,
    value: 'rentingRoom',
  },
];

export default HOME_TYPES;
