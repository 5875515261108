import textResource from '../../../../utils/text-resources/advisa/SE/messages';

const LOAN_PURPOSES = [
  {
    label: textResource.loanPurposeVehicles,
    value: 'vehicles',
  },
  {
    label: textResource.loanPurposeConsumption,
    value: 'consumption',
  },
  {
    label: textResource.loanPurposeHealth,
    value: 'health',
  },
  {
    label: textResource.loanPurposeRenovation,
    value: 'renovation',
  },
  {
    label: textResource.loanPurposeServices,
    value: 'services',
  },
  {
    label: textResource.loanPurposeBuffer,
    value: 'buffer',
  },
  {
    label: textResource.loanPurposeStudies,
    value: 'studies',
  },
];

const NEW_LOAN_FORM_PURPOSES = [
  ...LOAN_PURPOSES,
  {
    label: textResource.loanPurposeConsolidateLoans,
    value: 'consolidateLoans',
  },
];

const MORTGAGE_LOAN_PURPOSES = [
  {
    label: textResource.mortgageLoanPurposeRefinance,
    value: 'consolidateLoans',
  },
  {
    label: textResource.mortgageLoanPurposePropertyPurchase,
    value: 'propertyPurchase',
  },
];

export { LOAN_PURPOSES, NEW_LOAN_FORM_PURPOSES, MORTGAGE_LOAN_PURPOSES };
