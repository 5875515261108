export default {
  totalAmountMarginTop: '1rem',
  loanCalculatorBorderWidth: '2px',
  loanCalculatorValueColor: 'var(--green)',
  summaryButtonContent: '✓ ',
  summaryButtonSelectedBackground: 'var(--white)',
  summaryButtonSelectedColor: 'var(--teal)',
  userAgreementMarginTop: '.5rem',
  returningCustomerBankIDButtonColor: 'var(--mbc)',
  returningCustomerBankIDButtonTextColor: '#04585D',
  returningCustomerBackgroundColor: 'var(--advisa-wallet-100)',
  returningCustomerTextColor: '#04585D',
  returningCustomerTitleColor: '#000000',
};
