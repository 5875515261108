export default {
  ctaButtonBackground: 'var(--cta-color)',
  buttonBorderRadius: '12rem',
  buttonBorderRadiusLead: '12rem',
  buttonPadding: '2rem',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)',
  buttonWidth: '100%',
  checkMarkTextFontSize: '0.8rem',
  acceptNewsLetterMargin: '1em 0',
  storeValueMaxWidth: '5rem',
  breakHeight: '1rem',
  buttonTextColor: 'var(--white)',
};
