const LINK_URLS = {
  WEBSITE: 'https://advisa.se',
  CUSTOMERSUPPORTLINK: 'https://advisa.se/om-advisa/kontakta-oss/',
  AUTOMATED_PAYMENT_LINK: 'https://www.mvh.bgonline.se/Public.Web/Templates/MandateApplications/FillInMandateApplication.aspx?formRef=8a7659c7-2d0c-4238-beae-ca9a2042e223',
  INSURANCE_TERMS_LINK: 'https://static-assets.advisa.se/ppi/trygghetsforsakring-villkor.pdf',
  PRESALE_INFO_LINK: 'https://static-assets.advisa.se/ppi/trygghetsforsakring-for-och-efterkopsinformation.pdf',
  ECONOMY_HELPLINE: 'https://www.konsumenternas.se/kontakta-oss/',
  USER_AGREEMENT: 'https://advisa.se/om-advisa/anvandarvillkor/',
  DATA_POLICY_LINK: 'https://advisa.se/om-advisa/dataskyddspolicy/',
  COMPENSATION_LEVELS_EXAMPLE_LINK: 'https://static-assets.advisa.se/ppi/trygghetsforsakring-sa-har-vi-raknat.pdf',
  FORM_DATA_CONSENT_LINK: '#',
};

export default LINK_URLS;
