import textResource from '../../../../utils/text-resources/advisa/SE/messages';

const MARITAL_STATUS = [
  {
    label: textResource.civilStatusSingle,
    value: 'single',
  },
  {
    label: textResource.civilStatusPartner,
    value: 'partner',
  },
  {
    label: textResource.civilStatusMarried,
    value: 'married',
  },
];

export default MARITAL_STATUS;
