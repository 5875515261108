import { defineMessages } from 'react-intl';

const messages = defineMessages({
  safeAndSecure: {
    id: 'advisa.se.safeAndSecure',
    defaultMessage: 'Safe and secure',
    description: 'Label for lock seal on the bottom of the form',
  },
  defaultErrorHeadline: {
    id: 'advisa.se.errorBoundary.defaultErrorHeadline',
    defaultMessage: 'Something went wrong!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessage: {
    id: 'advisa.se.errorBoundary.defaultErrorMessage',
    defaultMessage: 'We currently have technical problems. Please contact customer support for assistance or try again!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessageWithPhoneNumber: {
    id: 'advisa.se.errorBoundary.defaultErrorMessageWithPhoneNumber',
    defaultMessage: 'We currently have technical problems. Click the button below and we will call you as soon as we can on <i> {customerPhoneNumber} </i> to continue your application',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  emailSubmissionInputPlaceholder: {
    id: 'advisa.se.errorBoundary.emailSubmissionInputPlaceholder',
    defaultMessage: 'Your email adress',
    description: 'The default input placeholder for email submission form',
  },
  emailSubmissionButtonLabel: {
    id: 'advisa.se.errorBoundary.emailSubmissionButtonLabel',
    defaultMessage: 'Email Submission',
    description: 'The default button label forEmail Submission form',
  },
  emailSubmissionSuccessMessage: {
    id: 'advisa.se.errorBoundary.emailSubmissionSuccessMessage',
    defaultMessage: 'Thank you!',
    description: 'Success message on the "Email Submission"-button in the form',
  },
  emailSubmissionErrorMessage: {
    id: 'advisa.se.errorBoundary.emailSubmissionErrorMessage',
    defaultMessage: 'We could not send your email, please try again later!',
    description: 'Error message on the "Email Submission"-button in the form',
  },
  totalLoanUseOnlyNumbers: {
    id: 'advisa.se.totalLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Informing the customer/applicant that only numbers are allowed for the total loan amount',
  },
  tooLowTotalLoan: {
    id: 'advisa.se.tooLowTotalLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to low. Also show what the minimum loan amount is',
  },
  tooHighTotalLoan: {
    id: 'advisa.se.tooHighTotalLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to high. Also show what the maximum loan amount is',
  },
  tooHighCurrentLoan: {
    id: 'advisa.se.tooHighCurrentLoan',
    defaultMessage: 'Highest loan amount is {maxLimit, number, currency}.',
    description: 'Error message informing the applicant that loan amount is to high',
  },
  textInputIsRequired: {
    id: 'advisa.se.textInputIsRequired',
    defaultMessage: 'This field can not be left empty',
    description: '',
  },
  buttonGroupIsRequired: {
    id: 'advisa.se.buttonGroupIsRequired',
    defaultMessage: 'Please select one.',
    description: 'Tells the customer that one option needs to be selected',
  },
  newPurposeHeaderText: {
    id: 'advisa.se.newPurposeHeaderText',
    defaultMessage: 'Loan purpose for the new loan part',
    description: 'Header in the application form for purpose of the part of the loan that is considered new loan',
  },
  checkBoxIsRequired: {
    id: 'advisa.se.checkBoxIsRequired',
    defaultMessage: 'Please consent to our terms and conditions',
    description: 'Tells the customer that checkox needs to be checked',
  },
  repaymentTimeUseOnlyNumbers: {
    id: 'advisa.se.repaymentTimeUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer that they are only allowed to use numbers',
  },
  tooShortRepaymentTime: {
    id: 'advisa.se.tooShortRepaymentTime',
    defaultMessage: 'Shortest possible repayment time is {loanMinYears} year.',
    description: 'Inform the customer that they have choosen a repayment time shorter than allowed',
  },
  tooLongRepaymentTime: {
    id: 'advisa.se.tooLongRepaymentTime',
    defaultMessage: 'Longest possible repayment time is {loanMaxYears} years.',
    description: 'Inform the customer thath they have choosen a repayment time further ahead than allowed',
  },
  purposeIsRequired: {
    id: 'advisa.se.purposeIsRequired',
    defaultMessage: 'Please choose a loan purpose.',
    description: 'Informing the customer that a cause/reason for the loan must be passed in',
  },
  numCurrentLoansIsRequired: {
    id: 'advisa.se.numCurrentLoansIsRequired',
    defaultMessage: 'Number of current loans are required.',
    description: 'Error message informing the applicant that the number of current loan must be provided',
  },
  numCurrentLoansUseOnlyNumbers: {
    id: 'advisa.se.numCurrentLoansUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed',
  },
  tooLowNumCurrentLoans: {
    id: 'advisa.se.tooLowNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loans provided is to low',
  },
  tooHighNumCurrentLoans: {
    id: 'advisa.se.tooHighNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loands provided is to high',
  },
  newLoanUseOnlyNumbers: {
    id: 'advisa.se.newLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer/applicant that they are only allowed to use numbers',
  },
  tooLowNewLoan: {
    id: 'advisa.se.tooLowNewLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'When a customer/applicant has filled in an amount lower than minimum amount allowed',
  },
  tooHighNewLoan: {
    id: 'advisa.se.tooHighNewLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'When a customer/applicant has filled in an amount higher than maximum amount allowed',
  },
  mergeLoanUseOnlyNumbers: {
    id: 'advisa.se.mergeLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed to be provided',
  },
  tooLowMergeLoan: {
    id: 'advisa.se.tooLowMergeLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to low and what the minimum requirements are',
  },
  tooHighMergeLoan: {
    id: 'advisa.se.tooHighMergeLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to high and what the maximum requirements are',
  },
  mergeLoanIsNotABoolean: {
    id: 'advisa.se.mergeLoanIsNotABoolean',
    defaultMessage: 'Please choose if you want to consolidate loans',
    description: 'Error message informing that merge is not of type boolean',
  },
  interestRateUseOnlyNumbers: {
    id: 'advisa.se.interestRateUseOnlyNumbers',
    defaultMessage: 'Please use only numbers',
    description: 'Informing the customer/applicant that only numbers are allowed for the interest rate',
  },
  tooLowInterestRate: {
    id: 'advisa.se.tooLowInterestRate',
    defaultMessage: 'The lowest effective interest rate you can use is {minAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to low. Also show what the minimum interest rate is',
  },
  tooHighInterestRate: {
    id: 'advisa.se.tooHighInterestRate',
    defaultMessage: 'The highest effective interest rate you can use is {maxAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to high. Also show what the maximum interest rate is',
  },
  interestRateHasTwoDots: {
    id: 'advisa.se.interestRateHasTwoDots',
    defaultMessage: "Please check if you've added too many dots as separators",
    description: 'Informing the customer/applicant that they have...',
  },
  nothingBeforeDotInInterestRate: {
    id: 'advisa.se.nothingBeforeDotInInterestRate',
    defaultMessage: 'Please enter a number before the separator',
    description: 'Informing the customer/applicant that they have...',
  },
  tooManyDecimalsInInterestRate: {
    id: 'advisa.se.tooManyDecimalsInInterestRate',
    defaultMessage: "Please check if you've added too many decimals",
    description: 'Informing the customer/applicant that they have...',
  },
  homeTypeIsRequired: {
    id: 'advisa.se.homeTypeIsRequired',
    defaultMessage: 'Please enter a housing situation.',
    description: 'Error message informing applicant that a type of housing must be provided',
  },
  employmentTypeIsRequired: {
    id: 'advisa.se.employmentTypeIsRequired',
    defaultMessage: 'Please enter an employment type',
    description: 'Inform the customer that a employment type must be provided',
  },
  employedToMonthIsRequired: {
    id: 'advisa.se.employedToMonthIsRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed until"-month must be selected',
  },
  employedToRequiredYear: {
    id: 'advisa.se.employedToRequiredYear',
    defaultMessage: 'Year is required.',
    description: 'Error message informing the applicant that a year must be filled in for the field "employed untill the year of"',
  },
  employedToRequiredMonth: {
    id: 'advisa.se.employedToRequiredMonth',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that a month must be filled in for the field "employed untill the month of',
  },
  employedToUseOnlyNumbers: {
    id: 'advisa.se.employedToUseOnlyNumbers',
    defaultMessage: 'Enter when your project employment ends, only digits (i.e. {nextYear}).',
    description: 'Error message informing the applicant that they need to fill in when their employment ends, only in numbers',
  },
  employedToFormat: {
    id: 'advisa.se.employedToFormat',
    defaultMessage: 'Enter the year with four digits (i.e. {nextYear})',
    description: 'Error message reminding the customer that the year needs to be in the correct format (xxxx)',
  },
  employedToDateIsInThePast: {
    id: 'advisa.se.employedToDateIsInThePast',
    defaultMessage: 'The end date must be a future date.',
    description: 'Error message informing the applicant that the choosen date was set to a date in the past',
  },
  employedToDateTooFarInTheFuture: {
    id: 'advisa.se.employedToDateTooFarInTheFuture',
    defaultMessage: 'The end date is too far in the future',
    description: 'Error message informing the applicant that the choosen date that is more than 100 years in the future',
  },
  employedSinceMonthRequired: {
    id: 'advisa.se.employedSinceMonthRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed since"-month must be selected',
  },
  requiredDependants: {
    id: 'advisa.se.requiredDependants',
    defaultMessage: 'Please enter number of children.',
    description: 'Error message informing the applicant that the number of dependants/kids havent been filled in',
  },
  dependantsUseOnlyNumbers: {
    id: 'advisa.se.dependantsUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that they may only use numbers when filling in the number of dependants/kids',
  },
  tooLowDependants: {
    id: 'advisa.se.tooLowDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too low of an amount of dependants',
  },
  tooHighDependants: {
    id: 'advisa.se.tooHighDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too high of an amount of dependants',
  },
  consolidateLoanUseOnlyNumber: {
    id: 'advisa.se.consolidateLoanUseOnlyNumber',
    defaultMessage: 'Use numbers please.',
    description: 'Asking the customer/applicant to only use numbers for the input',
  },
  tooHighConsolidateLoan: {
    id: 'advisa.se.tooHighConsolidateLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have entered a loan amount to high. Also shows the max allowed amount',
  },
  requiredCivilStatus: {
    id: 'advisa.se.requiredCivilStatus',
    defaultMessage: 'Please enter a martial status.',
    description: 'Error message informing the applicant that their civil state must be provided',
  },
  phoneNumberRequired: {
    id: 'advisa.se.phoneNumberRequired',
    defaultMessage: 'Please enter your phone number',
    description: '',
  },
  phoneNumberMobilePrefixError: {
    id: 'advisa.se.phoneNumberMobilePrefixError',
    defaultMessage: 'Your phone number must begin with 07, +46 or 0046.',
    description: '',
  },
  wrongPhoneNumberNumber: {
    id: 'advisa.se.wrongPhoneNumberNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  wrongPhoneNumberBeginning: {
    id: 'advisa.se.wrongPhoneNumberBeginning',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  missingOccupation: {
    id: 'advisa.se.missingOccupation',
    defaultMessage: 'Please enter your occupation',
    description: 'Error message',
  },
  requiredNationalId: {
    id: 'advisa.se.requiredNationalId',
    defaultMessage: 'A national ID is required.',
    description: 'Error message informing the applicant that a social security number/national ID must be provided',
  },
  wrongNationalIdFormat: {
    id: 'advisa.se.wrongNationalIdFormat',
    defaultMessage: 'Wrong format, for example: 131159-2950.',
    description: 'Error message informing the applicant that the social security number/national ID is wrongly formatted',
  },
  tooYoungNationalId: {
    id: 'advisa.se.tooYoungNationalId',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to low required to make an application',
  },
  tooOldNationalId: {
    id: 'advisa.se.tooOldNationalId',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to high required to make an application',
  },
  invalidNationalId: {
    id: 'advisa.se.invalidNationalId',
    defaultMessage: 'Invalid national ID.',
    description: 'Error message informing the applicant that the social security number/national ID is invalid',
  },
  employerPhoneMissingPhoneNumber: {
    id: 'advisa.se.employerPhoneMissingPhoneNumber',
    defaultMessage: 'Please enter a phone number',
    description: '',
  },
  requiredBankAccount: {
    id: 'advisa.se.requiredBankAccount',
    defaultMessage: 'Bank account number is required.',
    description: 'Error message informing the applicant that a bank account number must be provided',
  },
  invalidBankAccountFormat: {
    id: 'advisa.se.invalidBankAccountFormat',
    defaultMessage: 'Wrong format.',
    description: 'Error message informing the applicant that the bank account number is wrongly formatted',
  },
  invalidBankAccount: {
    id: 'advisa.se.invalidBankAccount',
    defaultMessage: 'Invalid bank account number',
    description: 'Error message informing the applicant that the bank account number is invalid',
  },
  coApplicantEmployedToYearPlaceholder: {
    id: 'advisa.se.coApplicantEmployedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToYearLabel: {
    id: 'advisa.se.coApplicantEmployedToYearLabel',
    defaultMessage: 'Employed to (year)',
    description: 'Label for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToMonthLabel: {
    id: 'advisa.se.coApplicantEmployedToMonthLabel',
    defaultMessage: 'Employed to (month)',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  coApplicantEmployedToMonthPlaceholder: {
    id: 'advisa.se.coApplicantEmployedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  coApplicantEmployedToTooltip: {
    id: 'advisa.se.coApplicantEmployedToTooltip',
    defaultMessage: 'Enter when their employment ends.',
    description: 'Tooltip for coApplicant "employed until"-input, placed inside form',
  },
  employedToMonthLabel: {
    id: 'advisa.se.employedToMonthLabel',
    defaultMessage: 'Employed to (month)',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  employedToYearPlaceholder: {
    id: 'advisa.se.employedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for "employed until"-input in years, placed inside form',
  },
  employedToYearLabel: {
    id: 'advisa.se.employedToYearLabel',
    defaultMessage: 'Employed to (year)',
    description: 'Label for "employed until"-input in years, placed inside form',
  },
  employedToMonthPlaceholder: {
    id: 'advisa.se.employedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  employedToTooltip: {
    id: 'advisa.se.employedToTooltip',
    defaultMessage: 'Enter when your employment ends.',
    description: 'Tooltip for "employed until"-input, placed inside form',
  },
  positiveReinforcementStableEmployment: {
    id: 'advisa.se.employmentType.stableEmployment',
    defaultMessage: 'Based on your information, your prospects for a loan with good terms are high!',
    description: 'Positive reinforcement message for a stable employment in the form.',
  },
  disabilityPensionSinceDynamicLabel: {
    id: 'advisa.se.disabilityPensionSinceDynamicLabel',
    defaultMessage: 'Disability pensioner since',
    description: 'Dynamic label for employedSinceYear',
  },
  retiredSinceDynamicLabel: {
    id: 'advisa.se.retiredSinceDynamicLabel',
    defaultMessage: 'Retired since',
    description: 'Dynamic label for employedSinceYear',
  },
  nextSlide: {
    id: 'advisa.se.nextSlide',
    defaultMessage: 'Next',
    description: 'general progress button label',
  },
  slideTitleOne: {
    id: 'advisa.se.slideTitleOne',
    defaultMessage: 'Your details',
    description: 'First slide title',
  },
  slideTitleTwo: {
    id: 'advisa.se.slideTitleTwo',
    defaultMessage: 'Employment',
    description: '',
  },
  slideTitleThree: {
    id: 'advisa.se.slideTitleThree',
    defaultMessage: 'Accommodation',
    description: '',
  },
  slideTitleFour: {
    id: 'advisa.se.slideTitleFour',
    defaultMessage: "Co-applicant's details",
    description: '',
  },
  repaymentTimePlaceholder: {
    id: 'advisa.se.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  repaymentTimeLabel: {
    id: 'advisa.se.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  repaymentTimeSuffix: {
    id: 'advisa.se.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  repaymentTimeTooltip: {
    id: 'advisa.se.repaymentTimeTooltip',
    defaultMessage: 'The repayment time is how long your loan is planned to be repaid. Depending on the time, the monthly cost of your loan might change, where shorter repayment time often means a higher monthly cost.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost',
  },
  addCoApplicantLabel: {
    id: 'advisa.se.addCoApplicantLabel',
    defaultMessage: 'Do you want to increase your chances of receiving more favorable offers by adding a co-applicant?',
    description: 'Experiment-variant for add co-applicant option that the applicant can use if they wish to share their loan with a co-applicant',
  },
  addCoApplicantDesc: {
    id: 'advisa.se.addCoApplicantDesc',
    defaultMessage: 'Adding a co-applicant often gives you an increased chance of getting a loan, and often at a better interest rate than without a co-applicant!',
    description: 'Description in add co-applicant textbox',
  },
  addCoApplicantTooltip: {
    id: 'advisa.se.addCoApplicantTooltip',
    defaultMessage: 'Co-applicants provides the banks with better security as it is deemed a lower risk for the banks when there are two people applying for the loan. This in hand often gives more favorable terms and interest rates for the applicant(s), depending on your economical situation.',
    description: 'Tooltip for adding co-applicant',
  },
  purposeHeaderText: {
    id: 'advisa.se.purposeHeaderText',
    defaultMessage: 'Loan purpose',
    description: 'Header in the application form for the group of loan purpose related inputs - The purpose of the loan',
  },
  purposeTooltip: {
    id: 'advisa.se.purposeTooltip',
    defaultMessage: 'If you have more than one purpose for the loan, state the main purpose. "Services" can be, for example, weddings, parties or cleaning jobs',
    description: 'Tooltip for The purpose of the loan',
  },
  civilStatusLabel: {
    id: 'advisa.se.civilStatusLabel',
    defaultMessage: 'Marital status',
    description: 'Label-text för civilstånd i formulär.',
  },
  civilStatusTooltip: {
    id: 'advisa.se.civilStatusTooltip',
    defaultMessage: 'What is your relationship status? If you have a partner and you live apart, please pick the option "Single".',
    description: 'Tooltip-text civilstånd i formulär.',
  },
  civilStatusSingle: {
    id: 'advisa.se.civilStatusSingle',
    defaultMessage: 'Single',
    description: 'Marital status for both applicants - single',
  },
  civilStatusPartner: {
    id: 'advisa.se.civilStatusPartner',
    defaultMessage: 'Cohabitant',
    description: 'Marital status for both applicants - partner',
  },
  civilStatusMarried: {
    id: 'advisa.se.civilStatusMarried',
    defaultMessage: 'Married',
    description: 'Marital status for both applicants - married',
  },
  coApplicantCivilStatusLabel: {
    id: 'advisa.se.coApplicantCivilStatusLabel',
    defaultMessage: 'Marital status',
    description: 'Label for civil status of co-applicant',
  },
  coApplicantCivilStatusTooltip: {
    id: 'advisa.se.coApplicantCivilStatusTooltip',
    defaultMessage: 'Please enter the relationship status of your co-applicant. If he/she is living separatly to their partner, use option "Single".',
    description: 'Tooltip-text for coApplicant civil status in form.',
  },
  applicationLoanAmountPlaceholder: {
    id: 'advisa.se.applicationLoanAmountPlaceholder',
    defaultMessage: 'XX XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountLabel: {
    id: 'advisa.se.applicationLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountSuffix: {
    id: 'advisa.se.applicationLoanAmountSuffix',
    defaultMessage: 'SEK',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountTooltip: {
    id: 'advisa.se.applicationLoanAmountTooltip',
    defaultMessage: 'Enter the amount for this loan.',
    description: 'Tooltip-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  currentLoanMergeLabel: {
    id: 'advisa.se.currentLoanMergeLabel',
    defaultMessage: 'I want to consolidate loans',
    description: 'Label-text för val av att lösa lån i formulär.',
  },
  currentLoanMergeTooltip: {
    id: 'advisa.se.currentLoanMergeTooltip',
    defaultMessage: 'Our partners can give you a more favorable interest rate if you chose to consolidate your loans. This way, they "buy out" parts or your whole loan situation and therefore merging them into a single loan',
    description: 'Tooltip-text för val av att lösa lån i formulär.',
  },
  dependantsLabel: {
    id: 'advisa.se.dependantsLabel',
    defaultMessage: 'Number of children under 21',
    description: 'Label-text för antal barn i formulär.',
  },
  dependantsTooltip: {
    id: 'advisa.se.dependantsTooltip',
    defaultMessage: 'Please enter the number of children living at home who are under 21 years old',
    description: 'Tooltip-text för antal barn i formulär.',
  },
  dependantsZero: {
    id: 'advisa.se.dependantsZero',
    defaultMessage: '0',
    description: 'No child',
  },
  dependantsOne: {
    id: 'advisa.se.dependantsOne',
    defaultMessage: '1',
    description: 'One child',
  },
  dependantsTwo: {
    id: 'advisa.se.dependantsTwo',
    defaultMessage: '2',
    description: 'Two children',
  },
  dependantsThree: {
    id: 'advisa.se.dependantsThree',
    defaultMessage: '3',
    description: 'Three children',
  },
  dependantsFour: {
    id: 'advisa.se.dependantsFour',
    defaultMessage: '4',
    description: 'Four children',
  },
  dependantsFive: {
    id: 'advisa.se.dependantsFive',
    defaultMessage: '5+',
    description: 'Five children',
  },
  coApplicantDependantsLabel: {
    id: 'advisa.se.coApplicantDependantsLabel',
    defaultMessage: 'Number of children under 21',
    description: 'Label text for number of children',
  },
  coApplicantDependantsTooltip: {
    id: 'advisa.se.coApplicantDependantsTooltip',
    defaultMessage: "Please enter the number of children living at your co-applicant's home who are under 21 years old",
    description: 'Tooltip-text for coApplicant dependants in form.',
  },
  emailPlaceholder: {
    id: 'advisa.se.emailPlaceholder',
    defaultMessage: 'Your email',
    description: 'Default-text för e-post i formulär.',
  },
  emailLabel: {
    id: 'advisa.se.emailLabel',
    defaultMessage: 'Email',
    description: 'Label-text för e-post i formulär.',
  },
  emailTooltip: {
    id: 'advisa.se.emailTooltip',
    defaultMessage: 'Your e-mail is required so we can contact and send out information regarding your application to you. If a bid is accepted, the banks will also communicate with you using this e-mail.',
    description: 'Tooltip-text för e-post i formulär.',
  },
  emailSuggestion: {
    id: 'advisa.se.emailSuggestion',
    defaultMessage: 'Did you mean',
    description: 'The pre-suggestion helptext at the email input field.',
  },
  emailMustBeUnique: {
    id: 'advisa.se.emailMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same email.',
    description: 'Informing the customer that the applicant and co-applicant can not use the same e-mail',
  },
  emailRequired: {
    id: 'advisa.se.emailRequired',
    defaultMessage: 'Please enter a valid e-mail',
    description: 'Error message informing the applicant that an email address must be provided',
  },
  emailNoSpaces: {
    id: 'advisa.se.emailNoSpaces',
    defaultMessage: 'The email cannot contain blank spaces.',
    description: 'Error message informing the applicant that the provided email address must not contain any spaces',
  },
  emailNoAt: {
    id: 'advisa.se.emailNoAt',
    defaultMessage: 'A valid e-mail needs to include @',
    description: 'Error message informing the applicant that the provided email address must contain the symbol "@"',
  },
  emailTooManyAts: {
    id: 'advisa.se.emailTooManyAts',
    defaultMessage: 'The email cannot contain more than one @.',
    description: 'Error message informing the applicant that the provided email address may not contain more than one "@"',
  },
  emailNoName: {
    id: 'advisa.se.emailNoName',
    defaultMessage: 'A valid e-mail needs something before the @',
    description: 'Error message informing the applicant that the email address must contain a name in from of the email address',
  },
  emailDotBeforeAtSign: {
    id: 'advisa.se.emailDotBeforeAtSign',
    defaultMessage: 'The e-mail domain cannot contain a dot (.) before @.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailTwoDots: {
    id: 'advisa.se.emailTwoDots',
    defaultMessage: 'The email cannot contain two dots in a row.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailNoDomain: {
    id: 'advisa.se.emailNoDomain',
    defaultMessage: 'A valid e-mail needs to end with .se/.com/.net or alike',
    description: 'Error message informing the applicant that the email address provided must end with a domain, for example ".com", ".se" or ".nu"',
  },
  emailNoTopLevelDomain: {
    id: 'advisa.se.emailNoTopLevelDomain',
    defaultMessage: 'A valid e-mail needs a web address after @',
    description: 'Error message informing the applicant that the email address provided must contain a domain name after the "@"',
  },
  coApplicantEmailLabel: {
    id: 'advisa.se.coApplicantEmailLabel',
    defaultMessage: 'Email',
    description: 'Label-text för e-post i formulär.',
  },
  coApplicantEmailPlaceholder: {
    id: 'advisa.se.coApplicantEmailPlaceholder',
    defaultMessage: 'Co-applicant’s email',
    description: 'Default-text for coApplicant e-mail in form.',
  },
  coApplicantEmailTooltip: {
    id: 'advisa.se.coApplicantEmailTooltip',
    defaultMessage: 'Your co-applicant’s email is needed so that your co-applicant can view the loan offers too.',
    description: 'Tooltip-text for coApplicant e-mail in form.',
  },
  coApplicantEmailRequired: {
    id: 'advisa.se.coApplicantEmailRequired',
    defaultMessage: 'Please enter a valid e-mail',
    description: 'Error message informing the applicant that an email address must be provided',
  },
  employerNamePlaceholder: {
    id: 'advisa.se.employerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default-text för arbetsgivarnamn i formulär.',
  },
  employerNameLabel: {
    id: 'advisa.se.employerNameLabel',
    defaultMessage: 'Employer name',
    description: 'Label-text för företagsnamn i formulär.',
  },
  employerNameTooltip: {
    id: 'advisa.se.employerNameTooltip',
    defaultMessage: 'Please enter the name of the company where you work.',
    description: 'Tooltip-text för företagsnamn i formulär.',
  },
  employerNameRequired: {
    id: 'advisa.se.employerNameRequired',
    defaultMessage: 'Please enter the name of the workplace you currently work at. If you have several employers, write a single one that you would consider is your main one. Our partners need this information to handle your application.',
    description: 'Error message informing the applicant that an employer must be provided',
  },
  coApplicantEmployerNamePlaceholder: {
    id: 'advisa.se.coApplicantEmployerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default text employer name',
  },
  coApplicantEmployerNameLabel: {
    id: 'advisa.se.coApplicantEmployerNameLabel',
    defaultMessage: 'Employer name',
    description: 'Label text for employer name',
  },
  coApplicantEmployerNameTooltip: {
    id: 'advisa.se.coApplicantEmployerNameTooltip',
    defaultMessage: "Please enter the name of the workplace your co-applicant's currently work at. If they have several employers, write a single one that would be considered to be the main one.",
    description: 'Tooltip-text for coApplicant company name in form.',
  },
  coApplicantEmployerNameRequired: {
    id: 'advisa.se.coApplicantEmployerNameRequired',
    defaultMessage: "Please enter the name of your co-applicant's employer",
    description: 'Error message informing the applicant that an employer must be provided',
  },
  employerPhonePlaceholder: {
    id: 'advisa.se.employerPhonePlaceholder',
    defaultMessage: 'Employer phone number',
    description: 'Default-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneLabel: {
    id: 'advisa.se.employerPhoneLabel',
    defaultMessage: 'Employer phone number',
    description: 'Label-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneTooltip: {
    id: 'advisa.se.employerPhoneTooltip',
    defaultMessage: 'Your employer telephone number can make it easier for our partners if they need additional information when considering giving you a loan proposal.',
    description: 'Tooltip-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneRequired: {
    id: 'advisa.se.employerPhoneRequired',
    defaultMessage: "Please enter your employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their employer',
  },
  coApplicantEmployerPhoneRequired: {
    id: 'advisa.se.coApplicantEmployerPhoneRequired',
    defaultMessage: "Please enter their employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their co applicants employer',
  },
  employerPhoneTooShort: {
    id: 'advisa.se.employerPhoneTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten a digit",
    description: 'Error message for when the customer fills in a too short phone number to their employer.',
  },
  employerPhoneTooLong: {
    id: 'advisa.se.employerPhoneTooLong',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number to their employer.',
  },
  coApplicantEmployerPhoneTooltip: {
    id: 'advisa.se.coApplicantEmployerPhoneTooltip',
    defaultMessage: "Your co-applicant's employer telephone number can make it easier for our partners if they need additional information when considering giving you a loan proposal.",
    description: 'Tooltip-text for coApplicant employer phone number in form.',
  },
  employerPhoneWrongNumber: {
    id: 'advisa.se.employerPhoneWrongNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid number',
    description: 'Error message for when the customer fills in a non-valid input for the number to their employer.',
  },
  employerPhoneWrongBeginning: {
    id: 'advisa.se.employerPhoneWrongBeginning',
    defaultMessage: 'The number needs to start with 0, +46 or 0046.',
    description: 'Error message for when the customer fills in a foreign phone number to their employer.',
  },
  employmentTypeLabel: {
    id: 'advisa.se.employmentTypeLabel',
    defaultMessage: 'Employment',
    description: 'Label-text för sysselsättnig i formulär.',
  },
  employmentTypeTooltip: {
    id: 'advisa.se.employmentTypeTooltip',
    defaultMessage: 'Please select your employment type. If you are on leave, choose the employment option from which you got your leave. If you have several employments, please select the one that you consider is your main one. The choice "Pensioner" also includes early retirees.',
    description: 'Tooltip-text för sysselsättnig i formulär.',
  },
  employmentTypePermanent: {
    id: 'advisa.se.employmentTypePermanent',
    defaultMessage: 'Permanent',
    description: 'Employment type - Tenured/employed indefinitely/untill further notice',
  },
  employmentTypeProbationary: {
    id: 'advisa.se.employmentTypeProbationary',
    defaultMessage: 'Trial period',
    description: 'Employment type - Probationary',
  },
  employmentTypeRetired: {
    id: 'advisa.se.employmentTypeRetired',
    defaultMessage: 'Pensioner',
    description: 'Employment type - Retired/early retirement',
  },
  employmentTypeDisabilityPension: {
    id: 'advisa.se.employmentTypeDisabilityPension',
    defaultMessage: 'Disability pension',
    description: 'Employment type - Retired/pensioner on disibility',
  },
  employmentTypeProject: {
    id: 'advisa.se.employmentTypeProject',
    defaultMessage: 'Fixed-term',
    description: 'Employment type - Temporary employment/employed during project',
  },
  employmentTypeByTheHour: {
    id: 'advisa.se.employmentTypeByTheHour',
    defaultMessage: 'Hourly wage',
    description: 'Employment type - Extra work/hourly contract',
  },
  employmentTypeEmployedAtOwnCompany: {
    id: 'advisa.se.employmentTypeEmployedAtOwnCompany',
    defaultMessage: 'Own business',
    description: 'Employment type - Has his/her own business',
  },
  coApplicantEmploymentTypeLabel: {
    id: 'advisa.se.coApplicantEmploymentTypeLabel',
    defaultMessage: 'Employment',
    description: 'Label-text för sysselsättnig i formulär.',
  },
  coApplicantEmploymentTypeTooltip: {
    id: 'advisa.se.coApplicantEmploymentTypeTooltip',
    defaultMessage: 'Please enter your co-applicants form of employment. If the the choice does not exist, take the one you think is closest. If there are more sources of income, indicate the one that is considered the main one. The "Pensioner" option also includes early retirees..',
    description: 'Tooltip-text for coApplicant employment in form.',
  },
  coApplicantEmploymentSinceYearPlaceholder: {
    id: 'advisa.se.coApplicantEmploymentSinceYearPlaceholder',
    defaultMessage: 'e.g 2002',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceYearLabel: {
    id: 'advisa.se.coApplicantEmploymentSinceYearLabel',
    defaultMessage: 'Employed since (year)',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceMonthLabel: {
    id: 'advisa.se.coApplicantEmploymentSinceMonthLabel',
    defaultMessage: 'Employed since (month)',
    description: 'Label for "employment since"-input in months, placed inside form',
  },
  coApplicantEmploymentSinceYearTooltip: {
    id: 'advisa.se.coApplicantEmploymentSinceYearTooltip',
    defaultMessage: 'Please enter approximately the year when your co-applicant started at their current company. Use 4 numbers, e.g "2002"',
    description: 'Tooltip for "employment since"-input',
  },
  coApplicantEmploymentSinceMonthPlaceholder: {
    id: 'advisa.se.coApplicantEmploymentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  employmentSinceMonthTooltip: {
    id: 'advisa.se.employmentSinceMonthTooltip',
    defaultMessage: "Please select approximately the month that your applicant started at their current company. If you don't know exactly, pick what you think it is.",
    description: 'Tooltip for "employment since"-input',
  },
  coApplicantEmploymentSinceMonthTooltip: {
    id: 'advisa.se.coApplicantEmploymentSinceMonthTooltip',
    defaultMessage: "Please select approximately the month that your co-applicant started at their current company. If you don't know exactly, pick what you think it is.",
    description: 'Tooltip for "employment since"-input',
  },
  employmentSinceMonthPlaceholder: {
    id: 'advisa.se.employmentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  employmentSinceYearPlaceholder: {
    id: 'advisa.se.employmentSinceYearPlaceholder',
    defaultMessage: 'e.g 2002',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  employmentSinceYearLabel: {
    id: 'advisa.se.employmentSinceYearLabel',
    defaultMessage: 'Employed since',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  employmentSinceYearTooltip: {
    id: 'advisa.se.employmentSinceYearTooltip',
    defaultMessage: 'Please enter approximately the year when you started at your current company. Use 4 numbers, e.g "2002".',
    description: 'Tooltip for "employment since"-input',
  },
  employedSinceMonthLabel: {
    id: 'advisa.se.employedSinceMonthLabel',
    defaultMessage: 'Employed since (month)',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  employedSinceMonthPlaceholder: {
    id: 'advisa.se.employedSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  retiredEmploymentSinceLabel: {
    id: 'advisa.se.retiredEmploymentSinceLabel',
    defaultMessage: 'Retired since',
    description: '',
  },
  retiredEmploymentSinceTooltip: {
    id: 'advisa.se.retiredEmploymentSinceTooltip',
    defaultMessage: 'Enter approximately when you retired.',
    description: '',
  },
  coApplicantRetiredEmploymentSinceTooltip: {
    id: 'advisa.se.coApplicantRetiredEmploymentSinceTooltip',
    defaultMessage: 'Enter approximately when your co-applicant retired.',
    description: 'Tooltip-text for coApplicant retired since in form.',
  },
  employedSinceRequiredYear: {
    id: 'advisa.se.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year you started your current employment',
    description: 'Error message informing the applicant that they must pass in which year their employment started',
  },
  coAppEmployedSinceRequiredYear: {
    id: 'advisa.se.coApplicant.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year your co-applicant started at their current employer',
    description: 'Error message informing the co applicant that they must pass in which year their employment started',
  },
  employedSinceRequiredMonth: {
    id: 'advisa.se.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month you started your current employment',
    description: 'Error message informing the applicant that they must pass in which month their employment started',
  },
  coAppEmployedSinceRequiredMonth: {
    id: 'advisa.se.coApplicant.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month your co-applicant started at their current employer',
    description: 'Error message informing the co applicant that they must pass in which month their employment started',
  },
  employedSinceStartTimeInTheFutureMonth: {
    id: 'advisa.se.employedSinceStartTimeInTheFutureMonth',
    defaultMessage: "The employment date can't be in the future",
    description: 'Error message informing the start date is in the future',
  },
  employedSinceOnlyNumbers: {
    id: 'advisa.se.employedSinceOnlyNumbers',
    defaultMessage: 'Please enter the year in digits (e.g. 2010).',
    description: 'Error message informing the applicant that they may only use digits when filling in which year their employment started',
  },
  employedSinceFourDigits: {
    id: 'advisa.se.employedSinceFourDigits',
    defaultMessage: 'Please write the year in 4 digits, e.g. 2010.',
    description: 'Error message informing the applicant that the format for "year their employment started" should be filled in with four digits',
  },
  employedSinceTooFarBack: {
    id: 'advisa.se.employedSinceTooFarBack',
    defaultMessage: 'Enter the year you started (e.g. 2010).',
    description: 'Error message informing the applicant that the year their employment started is set to far back in time',
  },
  coApplicantEmployedSinceTooFarBack: {
    id: 'advisa.se.coApplicant.employedSinceTooFarBack',
    defaultMessage: 'Enter the year they started (e.g. 2010).',
    description: 'Error message informing the co applicant started their employment to far back in time',
  },
  employedSinceStartTimeInTheFuture: {
    id: 'advisa.se.employedSinceStartTimeInTheFuture',
    defaultMessage: 'Your employment date cannot be in the future',
    description: 'Error message informing the applicant that their "start time of the employment" cannot be set to a date in the future',
  },
  coAppEmployedSinceStartTimeInTheFuture: {
    id: 'advisa.se.employedSinceCoApplicant.startTimeInTheFuture',
    defaultMessage: "Their employment date can't be in the future.",
    description: 'Error message informing the co applicant that their "start time of the employment" cannot be set to a date in the future',
  },
  homeCostPlaceholder: {
    id: 'advisa.se.homeCostPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Default-text för boendekostnad i formulär.',
  },
  homeCostLabel: {
    id: 'advisa.se.homeCostLabel',
    defaultMessage: 'Housing cost (SEK/mth)',
    description: 'Label-text för boendekostnad i formulär.',
  },
  homeCostTooltip: {
    id: 'advisa.se.homeCostTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If you are several who split the cost, enter your share in (e.g. half the total cost).',
    description: 'Tooltip-text för boendekostnad i formulär.',
  },
  homeCostRequired: {
    id: 'advisa.se.homeCostRequired',
    defaultMessage: 'Please enter your monthly housing cost',
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  homeCostOnlyDigits: {
    id: 'advisa.se.homeCostOnlyDigits',
    defaultMessage: 'Please write your housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  homeCostTooHigh: {
    id: 'advisa.se.homeCostTooHigh',
    defaultMessage: 'Your housing cost cannot be more than {maxCost, number} SEK/mth for you to apply',
    description: 'Inform the customer that we may unfortunatly only accept a monthly cost of "x" and that their cost is to high',
  },
  homeCostTooLow: {
    id: 'advisa.se.homeCostTooLow',
    defaultMessage: 'Enter monthly cost for your accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  coApplicantHomeCostPlaceholder: {
    id: 'advisa.se.coApplicantHomeCostPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Default-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostLabel: {
    id: 'advisa.se.coApplicantHomeCostLabel',
    defaultMessage: 'Housing cost (SEK/mth)',
    description: 'Label-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostTooltip: {
    id: 'advisa.se.coApplicantHomeCostTooltip',
    defaultMessage: "Please enter the monthly cost connected to your co-applicant's housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If there are several who split the cost, enter your co-applicant's share in (e.g. half the total cost).",
    description: 'Tooltip-text for coApplicant home cost in form.',
  },
  coApplicantHomeCostRequired: {
    id: 'advisa.se.coApplicantHomeCostRequired',
    defaultMessage: "Please enter your co-applicant's monthly housing cost",
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  coApplicantHomeCostOnlyDigits: {
    id: 'advisa.se.coApplicantHomeCostOnlyDigits',
    defaultMessage: 'Please write the housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  coApplicantHomeCostTooHigh: {
    id: 'advisa.se.coApplicantHomeCostTooHigh',
    defaultMessage: 'Their housing cost cannot be more than {maxCost, number} SEK/mth for you to apply',
    description: 'Inform the customer that we may unfortunatly only accept a monthly cost of "x" and that their cost is to high',
  },
  coApplicantHomeCostTooLow: {
    id: 'advisa.se.coApplicantHomeCostTooLow',
    defaultMessage: 'Enter monthly cost for their accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  homeTypeLabel: {
    id: 'advisa.se.homeTypeLabel',
    defaultMessage: 'Housing',
    description: 'Label-text för boendeform i formulär.',
  },
  homeTypeTooltip: {
    id: 'advisa.se.homeTypeTooltip',
    defaultMessage: 'If you have several addresses, please choose the one where you live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip-text för boendeform i formulär.',
  },
  homeTypeRentingApartment: {
    id: 'advisa.se.homeTypeRentingApartment',
    defaultMessage: 'Rented apartment',
    description: 'Residential type - Renting appartment',
  },
  homeTypeHouseOrTownhouse: {
    id: 'advisa.se.homeTypeHouseOrTownhouse',
    defaultMessage: 'House',
    description: 'Residential type - House',
  },
  homeTypeCondominium: {
    id: 'advisa.se.homeTypeCondominium',
    defaultMessage: 'Own apartment',
    description: 'Residential type - Condominium',
  },
  homeTypeRentingRoom: {
    id: 'advisa.se.homeTypeRentingRoom',
    defaultMessage: 'Live-in',
    description: 'Residential type - Renting room/lodger',
  },
  coApplicantHomeTypeLabel: {
    id: 'advisa.se.coApplicantHomeTypeLabel',
    defaultMessage: 'Housing situation',
    description: 'Label-text för boendeform i formulär.',
  },
  coApplicantHomeTypeTooltip: {
    id: 'advisa.se.coApplicantHomeTypeTooltip',
    defaultMessage: 'If your co-applicant have several addresses, please choose the one where they live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip-text for coApplicant home type in form.',
  },
  loanPurposeTooltip: {
    id: 'advisa.se.loanPurposeTooltip',
    defaultMessage: 'What are you mainly going to use the money for?',
    description: 'Tooltip-text för lånets syfte i formulär.',
  },
  loanPurposeRequired: {
    id: 'advisa.se.loanPurposeRequired',
    defaultMessage: 'The loan purpose is required.',
    description: '',
  },
  loanPurposeConsolidateLoans: {
    id: 'advisa.se.loanPurposeConsolidateLoans',
    defaultMessage: 'Consolidate loans',
    description: 'Purpose of the loan - Consolidate loans',
  },
  loanPurposeVehicles: {
    id: 'advisa.se.loanPurposeVehicles',
    defaultMessage: 'Vehicle',
    description: 'Purpose of the loan - Vehichle expenses',
  },
  loanPurposeConsumption: {
    id: 'advisa.se.loanPurposeConsumption',
    defaultMessage: 'Consumption',
    description: 'Purpose of the loan - Shopping/consumption',
  },
  loanPurposeHealth: {
    id: 'advisa.se.loanPurposeHealth',
    defaultMessage: 'Health/medical',
    description: 'Purpose of the loan - Health',
  },
  loanPurposeRenovation: {
    id: 'advisa.se.loanPurposeRenovation',
    defaultMessage: 'Renovation',
    description: 'Purpose of the loan - Renovation',
  },
  loanPurposeServices: {
    id: 'advisa.se.loanPurposeServices',
    defaultMessage: 'Services',
    description: 'Purpose of the loan - Services(for example "wedding")',
  },
  loanPurposeBuffer: {
    id: 'advisa.se.loanPurposeBuffer',
    defaultMessage: 'Investment',
    description: 'Purpose of the loan - Buffer/Investment',
  },
  loanPurposeStudies: {
    id: 'advisa.se.loanPurposeStudies',
    defaultMessage: 'Studies',
    description: 'Purpose of the loan - Studies',
  },
  mergeLoanYes: {
    id: 'advisa.se.mergeLoanYes',
    defaultMessage: 'Yes',
    description: '',
  },
  mergeLoanNo: {
    id: 'advisa.se.mergeLoanNo',
    defaultMessage: 'No',
    description: '',
  },
  monthlySalaryPlaceholder: {
    id: 'advisa.se.monthlySalaryPlaceholder',
    defaultMessage: 'E.g. 25 000',
    description: 'Default-text för inkomst i formulär.',
  },
  monthlySalaryLabel: {
    id: 'advisa.se.monthlySalaryLabel',
    defaultMessage: 'Total monthly net income (SEK/mth)',
    description: 'Label-text för inkomst i formulär.',
  },
  monthlySalaryTooltip: {
    id: 'advisa.se.monthlySalaryTooltip',
    defaultMessage: 'Enter your approximate total monthly income before taxes from all your income sources. This can consist of salary, comission, rental income or pension, to name a few. Note that you need to prove your stated income through payment slips or alike.',
    description: 'Tooltip-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryPlaceholder: {
    id: 'advisa.se.coApplicantMonthlySalaryPlaceholder',
    defaultMessage: 'E.g. 25 000',
    description: 'Default-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryLabel: {
    id: 'advisa.se.coApplicantMonthlySalaryLabel',
    defaultMessage: 'Total monthly net income (SEK/mth)',
    description: 'Label-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryTooltip: {
    id: 'advisa.se.coApplicantMonthlySalaryTooltip',
    defaultMessage: "Enter your co-applicant's approximate total monthly income before taxes. This can consist of salary, commission, rental income and pension, to name a few. Note that your co-applicant may need to prove their stated income through payment slips and alike.",
    description: 'Tooltip-text for coApplicant income in form.',
  },
  monthlySalaryRequired: {
    id: 'advisa.se.monthlySalaryRequired',
    defaultMessage: 'Please enter your monthly net income (SEK/mth)',
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  monthlySalaryOnlynumbers: {
    id: 'advisa.se.monthlySalaryOnlynumbers',
    defaultMessage: 'Please write your income in numbers only',
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  monthlySalaryTooLow: {
    id: 'advisa.se.monthlySalaryTooLow',
    defaultMessage: 'Your net income must be at least {minSalary, number} SEK/mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  monthlySalaryTooHigh: {
    id: 'advisa.se.monthlySalaryTooHigh',
    defaultMessage: 'Your net income must not be more than {maxSalary, number} SEK/mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  coApplicantMonthlySalaryRequired: {
    id: 'advisa.se.coApplicantMonthlySalaryRequired',
    defaultMessage: "Please enter your co-applicant's monthly net income (SEK/mth)",
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  coApplicantMonthlySalaryOnlynumbers: {
    id: 'advisa.se.coApplicantMonthlySalaryOnlynumbers',
    defaultMessage: "Please enter your co-applicant's income in numbers only",
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  coApplicantMonthlySalaryTooLow: {
    id: 'advisa.se.coApplicantMonthlySalaryTooLow',
    defaultMessage: 'Their net income must be at least {minSalary, number} SEK/mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  coApplicantMonthlySalaryTooHigh: {
    id: 'advisa.se.coApplicantMonthlySalaryTooHigh',
    defaultMessage: 'Their net income must not be more than {maxSalary, number} SEK/mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  nationalIdPlaceholder: {
    id: 'advisa.se.nationalIdPlaceholder',
    defaultMessage: 'YYMMDDXXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  nationalIdLabel: {
    id: 'advisa.se.nationalIdLabel',
    defaultMessage: 'National ID',
    description: 'Label-text för personnummer i formulär.',
  },
  nationalIdTooltip: {
    id: 'advisa.se.nationalIdTooltip',
    defaultMessage: 'The application is personal and your swedish national ID is needed to verify your identity. The information is secure with us and processed securely according to GDPR.',
    description: 'Tooltip-text för personnummer i formulär.',
  },
  nationalIdMustBeUnique: {
    id: 'advisa.se.nationalIdMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot be the same person.',
    description: 'Informing the customer that the applicant and co-applicant can not be the same person/use the same social security number',
  },
  nationalIdRequired: {
    id: 'advisa.se.nationalIdRequired',
    defaultMessage: 'Please enter your Swedish ID number',
    description: 'Error message for when a social security number must be provided',
  },
  nationalIdWrongFormat: {
    id: 'advisa.se.nationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 801122-0123',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correct formated social security number',
  },
  nationalIdWrongDate: {
    id: 'advisa.se.nationalIdWrongDate',
    defaultMessage: 'Your birth date {dateOfBirthString} is not valid. Please make sure there are no mistakes',
    description: 'Error message when providing birth date incorrectly',
  },
  nationalIdDateInTheFuture: {
    id: 'advisa.se.nationalIdDateInTheFuture',
    defaultMessage: "{dateOfBirthString} is a future date, and can't be used",
    description: 'Error message when choosing date of birth as a date in the future',
  },
  nationalIdTooYoung: {
    id: 'advisa.se.nationalIdTooYoung',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message when providing a date of birth making you younger than the minimum age allowed',
  },
  nationalIdTooOld: {
    id: 'advisa.se.nationalIdTooOld',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message when providing a date of birth making you older than the maximum age allowed',
  },
  nationalIdInvalid: {
    id: 'advisa.se.nationalIdInvalid',
    defaultMessage: 'Your 4 last digits do not seem to match your birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formated. Asks the customer to review their last four digits in their social security number',
  },
  coApplicantNationalIdPlaceholder: {
    id: 'advisa.se.coApplicantNationalIdPlaceholder',
    defaultMessage: 'YYMMDDXXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  coApplicantNationalIdLabel: {
    id: 'advisa.se.coApplicantNationalIdLabel',
    defaultMessage: 'National ID',
    description: 'Label-text för personnummer i formulär.',
  },
  coApplicantNationalIdTooltip: {
    id: 'advisa.se.coApplicantNationalIdTooltip',
    defaultMessage: "Since the application is personal, we need to verify your co-applicant national ID (don't write your own). This needs to be a swedish social security number",
    description: 'Tooltip-text for coApplicant in form.',
  },
  coApplicantNationalIdrequired: {
    id: 'advisa.se.coApplicantNationalIdrequired',
    defaultMessage: "Please enter your co-applicant's Swedish ID number",
    description: 'Error message for when a social security number must be provided',
  },
  coApplicantNationalIdWrongFormat: {
    id: 'advisa.se.coApplicantNationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 801122-0123',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correct formated social security number',
  },
  coApplicantNationalIdWrongDate: {
    id: 'advisa.se.coApplicantNationalIdWrongDate',
    defaultMessage: 'Their birth date {dateOfBirthString} is not valid, please make sure your input is correct',
    description: 'Error message when providing birth date incorrectly',
  },
  coApplicantNationalIdDateInTheFuture: {
    id: 'advisa.se.coApplicantNationalIdDateInTheFuture',
    defaultMessage: "{dateOfBirthString} is a future date, and can't be used",
    description: 'Error message when choosing date of birth as a date in the future',
  },
  coApplicantNationalIdInvalid: {
    id: 'advisa.se.coApplicantNationalIdInvalid',
    defaultMessage: 'Their 4 last digits seems to not match the birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formated. Asks the customer to review their last four digits in their social security number',
  },
  phoneNumberPlaceholder: {
    id: 'advisa.se.phoneNumberPlaceholder',
    defaultMessage: '07__ __ __ __',
    description: 'Default-text för mobilnummer i formulär.',
  },
  phoneNumberLabel: {
    id: 'advisa.se.phoneNumberLabel',
    defaultMessage: 'Phone number',
    description: '',
  },
  phoneNumberTooltip: {
    id: 'advisa.se.phoneNumberTooltip',
    defaultMessage: 'Your telephone number is required so we can give you information about your application. If a bid is accepted, the banks will also communicate with you using this telephone number.',
    description: 'Tooltip-text för mobilnummer i formulär.',
  },
  phoneNumberMustBeUnique: {
    id: 'advisa.se.phoneNumberMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same phone number.',
    description: 'Informing the customer/applicant that their co-applicant may not have an identical phone number as theirs',
  },
  phoneNumberWrongBeginning: {
    id: 'advisa.se.phoneNumberWrongBeginning',
    defaultMessage: 'We only accept cell phone numbers. The number needs to start with 07, +46 or 0046',
  },
  phoneNumberFormatError: {
    id: 'advisa.se.phoneNumberFormatError',
    defaultMessage: 'Must begin with 0 or Swedish country code (+46).',
    description: '',
  },
  phoneNumberPrefixError: {
    id: 'advisa.se.phoneNumberPrefixError',
    defaultMessage: 'Your phone number must begin with 04, 0457 or 050.',
    description: '',
  },
  phoneNumberWrongNumber: {
    id: 'advisa.se.phoneNumberWrongNumber',
    defaultMessage:
      'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  phoneNumberGeneralError: {
    id: 'advisa.se.phoneNumberGeneralError',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  phoneNumberTooShort: {
    id: 'advisa.se.phoneNumberTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten any digits",
    description: 'Error message for when the customer fills in a too short phone number.',
  },
  phoneNumberTooLong: {
    id: 'advisa.se.phoneNumberTooLong',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  coApplicantPhoneNumberTooltip: {
    id: 'advisa.se.coApplicantPhoneNumberTooltip',
    defaultMessage: "Your co-applicant's phone number is needed for them to be able to take part of the loan offers. When you have accepted a loan offer, banks might also need to come in contact with your co-applicant.",
    description: 'Tooltip-text for coApplicant mobile number in form.',
  },
  coApplicantPhoneNumberPlaceholder: {
    id: 'advisa.se.coApplicantPhoneNumberPlaceholder',
    defaultMessage: '07__ __ __ __',
    description: 'Default-text för coApplicant mobilnummer i formulär.',
  },
  coApplicantPhoneNumberLabel: {
    id: 'advisa.se.coApplicantPhoneNumberLabel',
    defaultMessage: 'Phone number',
    description: 'label for coApplicant mobile number in form.',
  },
  coApplicantPhoneNumberRequired: {
    id: 'advisa.se.coApplicantPhoneNumberRequired',
    defaultMessage: "Please enter your co-applicant's phone number",
  },
  betterTermsText: {
    id: 'advisa.se.betterTermsText',
    defaultMessage: 'Having a co-applicant often results in better conditions.',
    description: '',
  },
  submitErrorGeneral: {
    id: 'advisa.se.submitErrorGeneral',
    defaultMessage: 'Your application could not be submitted. Customer service is more than happy to help you through the process. Call: <a>{phoneNumber}</a> or click the button below and we will call you as soon as we can on <i>{customerPhoneNumber}</i>',
    description: 'Default-text för back-end fel i formulär.',
  },
  submitErrorUncaught: {
    id: 'advisa.se.submitErrorUncaught',
    defaultMessage: 'For the moment we have technical issues and your application could not be submitted. Please contact customer service for more help',
    description: 'Default-text for when a customer tries to submit their application but there was an error on the client side.',
  },
  validationErrorGeneral: {
    id: 'advisa.se.validationErrorGeneral',
    defaultMessage: 'Some of the fields are not filled in correctly. Please correct these and try to submit again.',
    description: 'Default-text för fel i fält.',
  },
  validationErrorField: {
    id: 'advisa.se.validationErrorField',
    defaultMessage: 'This field is not filled in correctly. Please correct it and try submitting again.',
    description: 'Default-text för fel i fält.',
  },
  fieldAlreadyInUseEmail: {
    id: 'advisa.se.fieldAlreadyInUseEmail',
    defaultMessage: 'This email address already belongs to an application.',
    description: 'Field error message informing the customer that the given e-mail is already in use in another application',
  },
  fieldAlreadyInUseNationalId: {
    id: 'advisa.se.fieldAlreadyInUseNationalId',
    defaultMessage: 'You have an ongoing application, to access it — click "Log in" in the menu. To make changes to your application, please call customer service on: <span>{phoneNumber}</span>',
    description: 'Default-text för back-end fel i formulär.',
  },
  formSubmit: {
    id: 'advisa.se.formSubmit',
    defaultMessage: 'Apply now - for free',
    description: 'Label for submit button',
  },
  monthlyCostApproximateMonthlyCostLabel: {
    id: 'advisa.se.monthlyCostApproximateMonthlyCostLabel',
    defaultMessage: 'Monthly cost approx. *',
    description: 'The label for the apprax. monthly cost',
  },
  monthlyCostApproximateCostLabel: {
    id: 'advisa.se.monthlyCostApproximateCostLabel',
    defaultMessage: 'Approximate cost',
    description: 'approx cost',
  },
  monthlyCostApproximateCostPerMonthDesktop: {
    id: 'advisa.se.monthlyCostApproximateCostPerMonthDesktop',
    defaultMessage: '{monthlyCost} / month *',
    description: 'Shows the apprx. monthly cost on desktop',
  },
  monthlyCostApproximateCostPerMonthMobile: {
    id: 'advisa.se.monthlyCostApproximateCostPerMonthMobile',
    defaultMessage: '<strong>{monthlyCost}</strong>/mth*',
    description: 'Shows the apprx. monthly cost on mobile',
  },
  monthlyCostApproximateCostLabelYears: {
    id: 'advisa.se.monthlyCostApproximateCostLabelYears',
    defaultMessage: 'in <strong> {repaymentTime} years </strong>',
    description: 'Repayment time in years',
  },
  combinedLoanConsolidationAmount: {
    id: 'advisa.se.combinedLoanConsolidationAmount',
    defaultMessage: 'Loans to consolidate: <strong>{consolidationAmount}</strong>',
    description: 'Part of the loan that is consolidation amount',
  },
  advisaPlusText: {
    id: 'advisa.se.advisaPlusText',
    defaultMessage: 'I want to become a member of Advisa Plus to receive news letters and offers via SMS and email. ',
    description: 'Text besides advisa plus checkbox in form',
  },
  advisaPlusReadMore: {
    id: 'advisa.se.advisaPlusReadMore',
    defaultMessage: 'Read more',
    description: 'Text for link to read about advisa Plus',
  },
  repaymentTimeWarning: {
    id: 'advisa.se.repaymentTimeWarning',
    defaultMessage: 'Most lenders offer a maximum repayment time of 15 years. These banks offer a maximum of 15 years, even if you apply for longer than that.',
    description: 'warning when entering over 15 years repayment time',
  },
  loanCalculatorTotalLoanAmountLabel: {
    id: 'advisa.se.loanCalculator.totalLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountPlaceholder: {
    id: 'advisa.se.loanCalculator.totalLoanAmountPlaceholder',
    defaultMessage: 'XX XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountSuffix: {
    id: 'advisa.se.loanCalculator.totalLoanAmountSuffix',
    defaultMessage: 'SEK',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountTooltip: {
    id: 'advisa.se.loanCalculator.totalLoanAmountTooltip',
    defaultMessage: 'Please enter the total loan amount for the loan you want to use for calculation',
    description: 'Tooltip-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorInterestRateLabel: {
    id: 'advisa.se.loanCalculator.interestRateLabel',
    defaultMessage: 'Effective interest rate',
    description: 'Label for interest rate.',
  },
  loanCalculatorInterestRatePlaceholder: {
    id: 'advisa.se.loanCalculator.interestRatePlaceholder',
    defaultMessage: 'X.XX',
    description: 'Default text for interest rate.',
  },
  loanCalculatorInterestRateSuffix: {
    id: 'advisa.se.loanCalculator.interestRateSuffix',
    defaultMessage: '%',
    description: 'Suffix for interest rate.',
  },
  loanCalculatorInterestRateTooltip: {
    id: 'advisa.se.loanCalculator.interestRateTooltip',
    defaultMessage: 'The interest rate on loans can be seen as a monthly fee to the banks for lending you the money. Our partners offered interest rate is decided based on your financial situation and can vary. You can read more about effective interest rate here.',
    description: 'Tooltip for interest rate for the loan in this form.',
  },
  loanCalculatorRepaymentTimeLabel: {
    id: 'advisa.se.loanCalculator.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  loanCalculatorRepaymentTimePlaceholder: {
    id: 'advisa.se.loanCalculator.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  loanCalculatorRepaymentTimeSuffix: {
    id: 'advisa.se.loanCalculator.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  loanCalculatorRepaymentTimeTooltip: {
    id: 'advisa.se.loanCalculator.repaymentTimeTooltip',
    defaultMessage: 'The repayment time is how long your loan is planned to be repaid. Depending on the time, the monthly cost of your loan might change, where shorter repayment time often means a higher monthly cost.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost',
  },
  loanCalculatorSetUpFeeLabel: {
    id: 'advisa.se.loanCalculator.setUpFeeLabel',
    defaultMessage: 'Setup fee',
    description: 'Label text for start up fee.',
  },
  loanCalculatorSetUpFeePlaceholder: {
    id: 'advisa.se.loanCalculator.setUpFeePlaceholder',
    defaultMessage: 'SEK 300',
    description: 'Placeholder text for start up fee.',
  },
  loanCalculatorSetUpFeeTooltip: {
    id: 'advisa.se.loanCalculator.setUpFeeTooltip',
    defaultMessage: 'Some banks have a single setup fee and/or recurring avia fees connected to your loan. You can usually find this info in the terms of the loan or on the invoice.',
    description: 'Tooltip text for start up fee.',
  },
  loanCalculatorAviaFeeLabel: {
    id: 'advisa.se.loanCalculator.aviaFeeLabel',
    defaultMessage: 'Avia Fee',
    description: 'Label text for fee.',
  },
  loanCalculatorAviaFeePlaceholder: {
    id: 'advisa.se.loanCalculator.aviaFeePlaceholder',
    defaultMessage: 'SEK 25',
    description: 'Label text for avia fee.',
  },
  loanCalculatorAviaFeeTooltip: {
    id: 'advisa.se.loanCalculator.aviaFeeTooltip',
    defaultMessage: 'Some banks have a single setup fee and/or recurring avia fees connected to your loan. You can usually find this info in the terms of the loan or on the invoice.',
    description: 'Tooltip text for avia fee.',
  },
  loanCalculatorNextSlideButtonHeading: {
    id: 'advisa.se.loanCalculator.nextSlideButtonHeading',
    defaultMessage: 'Do you want to apply for a loan based on the calculation above?',
    description: 'Title above the teleporter',
  },
  loanCalculatorNextSlideButtonSubHeading: {
    id: 'advisa.se.loanCalculator.nextSlideButtonSubHeading',
    defaultMessage: 'Fill out the information on the next page',
    description: 'Subtitle above the teleporter',
  },
  loanCalculatorNextSlideButton: {
    id: 'advisa.se.loanCalculator.nextSlideButton',
    defaultMessage: 'To application',
    description: 'General progress button text',
  },
  loanCalculatorAmortizationTypeLabel: {
    id: 'advisa.se.loanCalculator.summaryPage.amortizationTypeLabel',
    defaultMessage: 'Type of amortization',
    description: 'Label for which type of amortization the user wants to calculate with',
  },
  loanCalculatorAmortizationTypeAnnuity: {
    id: 'advisa.se.loanCalculator.summaryPage.amortizationTypeAnnuity',
    defaultMessage: 'Annuity',
    description: 'Text for amortization type annuity',
  },
  loanCalculatorAmortizationTypeStraight: {
    id: 'advisa.se.loanCalculator.summaryPage.amortizationTypeStraight',
    defaultMessage: 'Straight',
    description: 'Text for amortization type straight',
  },
  loanCalculatorAmortizationTypeTooltip: {
    id: 'advisa.se.loanCalculator.summaryPage.amortizationTypeTooltip',
    defaultMessage: 'Amortization essentially means that you pay back a small portion of the loan every month. Samblas partners can provide either Annuity loans or Straight loans. Annuity loans are the most common and is where you pay the same monthly cost every month.',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsForLoan: {
    id: 'advisa.se.loanCalculator.summaryPage.totalCostsForLoan',
    defaultMessage: 'Total cost',
    description: 'Text for total costs',
  },
  loanCalculatorTotalCostsForLoanTooltip: {
    id: 'advisa.se.loanCalculator.summaryPage.totalCostsForLoanTooltip',
    defaultMessage: 'You need to pay back the whole sum of the loan through the amortization plan, but there are also fees and interest costs connected to the loan that are added and needs to be paid.',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoan: {
    id: 'advisa.se.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoan',
    defaultMessage: 'Interest and fees',
    description: 'Text for total costs and interest and fees for loan',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: {
    id: 'advisa.se.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoanTooltip',
    defaultMessage: 'These are the total fees and interest costs connected to the loan. This excludes the loan amount, and with it, the amortization.',
    description: 'Text for tooltip',
  },
  loanCalculatorMonthlyCost: {
    id: 'advisa.se.loanCalculator.summaryPage.monthlyCost',
    defaultMessage: 'Monthly cost:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostMonthOne: {
    id: 'advisa.se.loanCalculator.summaryPage.monthlyCostMonthOne',
    defaultMessage: 'Monthly cost, month 1:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostTooltip: {
    id: 'advisa.se.loanCalculator.summaryPage.monthlyCostTooltip',
    defaultMessage: 'This is the recurring monthly cost for you until you have paid back your loan. Depending on your amortization type, these costs might change with time. Click on the overview below to see in more detail.',
    description: 'Text for tooltip',
  },

  loanCalculatorMonthlyCostOverviewButtonText: {
    id: 'advisa.se.loanCalculator.summaryPage.monthlyCostOverviewButtonText',
    defaultMessage: 'See overview of monthly cost',
    description: 'Text for button',
  },

  loanCalculatorSummaryTablePageDescription: {
    id: 'advisa.se.loanCalculator.summaryPage.summaryTablePageDescription',
    defaultMessage: 'More info',
    description: 'Text for page description',
  },
  loanCalculatorEffectiveInterest: {
    id: 'advisa.se.loanCalculator.summaryPage.effectiveInterest',
    defaultMessage: 'Effective interest:',
    description: 'Text for summary table effective interest',
  },
  loanCalculatorSummaryTableColumnMonth: {
    id: 'advisa.se.loanCalculator.summaryPage.summaryTableColumnMonth',
    defaultMessage: 'Month',
    description: 'Text for summary table month column',
  },
  loanCalculatorSummaryTableColumnDebtBeforeAmortization: {
    id: 'advisa.se.loanCalculator.summaryPage.summaryTableColumnDebtBeforeAmortization',
    defaultMessage: 'Debt before amortization',
    description: 'Text for summary table debt before amortization column',
  },
  loanCalculatorSummaryTableColumnAmortization: {
    id: 'advisa.se.loanCalculator.summaryPage.summaryTableColumnAmortization',
    defaultMessage: 'Amortization',
    description: 'Text for summary table amortization column',
  },
  loanCalculatorSummaryTableColumnInterestCost: {
    id: 'advisa.se.loanCalculator.summaryPage.summaryTableColumnInterestCost',
    defaultMessage: 'Interest',
    description: 'Text for summary table interest cost column',
  },
  loanCalculatorSummaryTableColumnFeeCost: {
    id: 'advisa.se.loanCalculator.summaryPage.summaryTableColumnFeeCost',
    defaultMessage: 'Fees',
    description: 'Text for summary table fee cost column',
  },
  loanCalculatorSummaryTableColumnToPay: {
    id: 'advisa.se.loanCalculator.summaryPage.summaryTableColumnToPay',
    defaultMessage: 'To pay',
    description: 'Text for summary table to pay column',
  },
  loanCalculatorCurrencySuffix: {
    id: 'advisa.se.loanCalculator.summaryPage.currencySuffix',
    defaultMessage: 'SEK',
    description: 'Suffix text for amounts in the loan calculator etc. kr or SEK',
  },
  feesCostRequired: {
    id: 'advisa.se.loanCalculator.feesCostRequired',
    defaultMessage: 'Please enter the fee',
    description: 'Fee description',
  },
  feesCostOnlyDigits: {
    id: 'advisa.se.loanCalculator.feesCostOnlyDigits',
    defaultMessage: 'Please write your fees cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the fees cost.',
  },
  feesCostTooHigh: {
    id: 'advisa.se.loanCalculator.feesCostTooHigh',
    defaultMessage: 'Your fees cost cannot be more than {maxCost, number} SEK/mth.',
    description: 'Inform the customer that we may unfortunatly only accept a fees cost of "x" and that their cost is to high',
  },
  feesCostTooLow: {
    id: 'advisa.se.loanCalculator.feesCostTooLow',
    defaultMessage: 'Please enter the fees connected to the loan',
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  feesCostStartWithZero: {
    id: 'sambla.se.loanCalculator.feesCostStartWithZero',
    defaultMessage: "Please don't start your input with a 0",
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  monthlySalaryAfterTaxInvalid: {
    id: 'advisa.se.monthlySalaryAfterTaxInvalid',
    defaultMessage: "Can't be higher then before taxes",
    description: 'Inform the user that they cant have more money after taxes then before.',
  },
  duplicateApplicationErrorMessage: {
    id: 'advisa.se.submitError.duplicateApplication',
    defaultMessage: 'It looks like you already have a valid application. You can find it by logging into your <a>user account</a>. If you want to make changes to the application, please contact our customer service on',
    description: 'Text for the error message that is displayed to the customer when they already have a active application.',
  },
  errorMessageTitle: {
    id: 'advisa.se.submitError.title',
    defaultMessage: 'Wops!',
    description: 'Title for the error that is displayed on submit.',
  },
  newLoanLimitWarning: {
    id: 'advisa.se.newLoanLimitWarning',
    defaultMessage: 'Note! A loan amount over 600k requires you to refinance/collect loans and credits you already have.',
    description: 'warning for when new loan amount is over 600k',
  },
});

export default messages;
