import textResource from '../../../../utils/text-resources/advisa/SE/messages';

const DEPENDANTS = [
  {
    label: textResource.dependantsZero,
    value: '0',
  },
  {
    label: textResource.dependantsOne,
    value: '1',
  },
  {
    label: textResource.dependantsTwo,
    value: '2',
  },
  {
    label: textResource.dependantsThree,
    value: '3',
  },
  {
    label: textResource.dependantsFour,
    value: '4',
  },
  {
    label: textResource.dependantsFive,
    value: '5',
  },
];
export default DEPENDANTS;
